@import "../variables";

.statistics {
  padding: 30px 0;

  @media ($tablet) {
    padding: 90px 0;
  }
}

.statistics__list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  @media ($tablet) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.statistics__item {
  margin-bottom: 30px;

  @media ($tablet) {
    width: 31%;
    margin-bottom: 60px;

    &--half {
      width: 47%;
    }
  }
}

.statistics__title {
  font-family: $fontMedium;
  font-size: 50px;
  line-height: 66px;
  margin: 0 0 11px;
}

.statistics__text {
  margin: 0;
  line-height: 23px;
}