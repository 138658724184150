@import "../variables";

.map {
  width: 100%;
  height: 470px;

  @media ($tablet) {
    height: 880px;
  }

  @media ($desktop) {
    height: 768px;
  }

  [class*="ymaps-2"][class*="-ground-pane"] {
    filter: grayscale(1);
  }
}