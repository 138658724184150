@import "../variables";

.about {
  padding: 92px 0 60px;

  @media ($desktop) {
    padding: 140px 0 210px;
  }

  .title {
    @media ($tablet) {
      display: flex;
      justify-content: space-between;

      span {
        width: 50%;
        box-sizing: border-box;

      }
    }

    @media ($desktop) {
      justify-content: flex-start;

      span.about__slogan {
        width: 300px;
      }
    }
  }
}

.about__content {
  @media ($desktop) {
    column-count: 2;
    column-gap: 30px;
  }
}

.about__text {
  line-height: 24px;

  @media ($desktop) {
    margin: 0 0 16px;
  }
}

.about__slogan {
  display: none;

  @media ($tablet) {
    display: block;
    font-size: 20px;
    line-height: 28px;
    padding-left: 45px;
  }

  @media ($desktop) {
    font-size: 24px;
    line-height: 33px;
  }
}