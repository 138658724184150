@import "../variables";

.nav {
  @media ($tablet) {
    order: 3;
    width: 100%;
  }

  @media ($desktop) {
    order: 2;
    width: auto;
  }
}

.nav__list {
  margin: 57px 0 0;
  padding: 0;
  list-style-type: none;

  @media ($tablet) {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }

  @media ($desktop) {
    margin-top: 0;
    justify-content: flex-start;
  }
}

.nav__item {
  margin-bottom: 28px;

  @media ($tablet) {
    margin-bottom: 0;
  }

  @media ($desktop) {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.nav__link {
  font-family: $fontMedium;
  line-height: 10px;
  letter-spacing: -0.36px;
  color: #2c2f34;
  text-decoration: none;
  transition: color .1s;

  &:hover {
    color: #03bc4d;
  }

  @media ($tablet) {
    color: #fff;
  }
}