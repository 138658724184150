@import "../variables";

.table-wrapper {
  overflow-x: auto;
  margin: 30px 0;
}

.table {
  border-collapse: collapse;

  @media ($tablet) {
    width: 100%;
  }

  td,
  th {
    min-width: 240px;

    @media ($tablet) {
      min-width: 200px;
    }
  }

  th {
    height: 90px;
    text-align: left;
    font-family: $fontMedium;
    font-size: 24px;
    line-height: 22px;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
  }

  td {
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    line-height: 22px;
    padding: 9px 0 23px;
  }
}