@import "../variables";

.rate {
  padding: 30px 0;

  &--bg {
    @media ($tablet) {
      background-color: #f5f7f9;
    }
  }
}

.rate__list {
  margin: 0 -15px;
  padding: 0;
  list-style-type: none;

  @media ($tablet) {
    margin: 0;
    display: flex;
  }

  &--3 {
    @media ($tablet) {
      .rate__item {
        width: 33.33%;
      }
    }
  }
}

.rate__item {
  margin-bottom: 60px;

  @media ($tablet) {
    margin-bottom: 0;
    width: 25%;
  }

  &--active {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
    border-style: solid;
    border-width: 4px;
    border-image-source: linear-gradient(to bottom, #06d270, #03bc4d);
    border-image-slice: 1;

    @media ($tablet) {
      margin-top: -4px;
    }
  }
}

.rate__header {
  border-top: 1px solid #d8d8d8;
  padding: 20px 15px;
  font-family: $fontMedium;
  font-size: 24px;
  line-height: 22px;
}

.rate__title {
  margin: 0 0 10px;
}

.rate__sum {
  margin: 0;
  color: #03bc4d;
}

.rate__row {
  border-top: 1px solid #d8d8d8;
  padding: 9px 15px 23px;
  line-height: 22px;

  &--active {
    font-family: $fontBold;
    color: #03bc4d;
  }
}

.rate__button {
  margin-top: 25px;
  padding: 0 15px 20px;
}