@import "../variables";

.popup-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
  justify-content: center;
  align-items: center;
  display: none;

  &--show {
    display: flex;
  }
}