@import "../variables";

.popup {
  border-radius: 6px;
  box-shadow: 0 52px 64px 0 rgba(0, 0, 0, 0.3);
  background-color: #fff;
  position: relative;
  box-sizing: border-box;
}

.popup__close {
  position: absolute;
  top: 15px;
  right: 15px;
}

.popup__close-svg {
  width: 15px;
  height: 15px;
}