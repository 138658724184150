@import "../variables";

.blockquote {
  margin: 30px 0;

  @media ($tablet) {
    position: relative;
    padding-left: 120px;
  }
}

.blockquote__svg {
  width: 40px;
  height: 37px;
  margin-bottom: 12px;

  @media ($tablet) {
    margin-bottom: 0;
    position: absolute;
    left: 50px;
    top: 0;
  }
}

.blockquote__content {
  font-family: $fontMedium;
  font-size: 20px;
  line-height: 28px;
  margin: 0 0 25px;

  @media ($tablet) {
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 20px;
  }
}

.blockquote__name {
  margin: 0;
  font-family: $fontBold;
  line-height: 20px;
}

.blockquote__type {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  opacity: .4;
}
