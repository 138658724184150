@import "variables";

.body {
  background-color: #fff;
  font-family: $fontRegular;
  font-size: 16px;
  color: #2c2f34;
  overflow-x: hidden;

  &--bg {
    background-color: #f5f7f9;

    .header__button-svg {
      fill: #2c2f34;
    }

    .header__phone,
    .header__callback,
    .nav__link {
      color: #2c2f34;
    }
  }

  &--default {
    .header__button-svg {
      fill: #2c2f34;
    }

    .header__phone,
    .header__callback,
    .nav__link {
      color: #2c2f34;
    }
  }
}

.container {
  padding: 0 15px;
  box-sizing: border-box;

  @media ($tablet) {
    padding: 0 45px;
  }

  @media ($desktop) {
    width: 1170px;
    padding: 0;
    margin: 0 auto;
  }
}

.visually-hidden {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none;

  &--open {
    display: block;
  }
}