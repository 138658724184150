@import "../variables";

.contacts {
  @media ($tablet) {
    position: relative;
  }

  @media ($desktop) {
    .container {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
    }
  }
}

.contacts__content {
  background-color: #fff;
  padding: 30px 0;

  @media ($tablet) {
    width: 370px;
    box-sizing: border-box;
    padding: 30px;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }

  @media ($desktop) {
    top: 200px;
    right: 0;
    left: auto;
    transform: none;
  }
}

.contacts__item {
  margin-bottom: 20px;
}

.contacts__label {
  line-height: 28px;
  margin: 0;
}

.contacts__text {
  margin: 0;
  font-family: $fontBold;
  line-height: 28px;

  span {
    font-family: $fontRegular;
  }
}

.contacts__phone {
  font-family: $fontBold;
  font-size: 32px;
  color: #2c2f34;
  text-decoration: none;
}

.contacts__button {
  margin-top: 40px;
}