@import "../variables";

.contact {
  padding: 30px 0 0;

  @media ($tablet) {
    padding: 60px 0 0;
  }

  @media ($desktop) {
    padding: 40px 0 0;
  }

  .title {
    font-size: 32px;
    line-height: 45px;

    @media ($tablet) {
      font-size: 50px;
      line-height: 67px;
    }

    @media ($desktop) {
      font-size: 80px;
      line-height: 95px;
      margin-bottom: 40px;
    }
  }
}

.contact__content {
  @media ($tablet) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.contact__phones {
  margin-bottom: 40px;

  @media ($tablet) {
    margin-bottom: 0;
    width: 47%;
  }

  @media ($desktop) {
    width: 315px;
  }
}

.contact__title {
  font-family: $fontBold;
  text-transform: uppercase;
  margin: 0 0 20px;
}

.contact__row {
  margin-bottom: 20px;
}

.contact__label {
  margin: 0 0 5px;
}

.contact__phone {
  font-family: $fontBold;
  font-size: 30px;
  color: #2c2f34;
  text-decoration: none;

  @media ($tablet) {
    padding: 6px 0 4px;
    display: inline-block;
  }
}

.contact__times {
  margin-bottom: 60px;

  @media ($tablet) {
    margin-bottom: 0;
    width: 47%;
    padding-top: 50px;
  }

  @media ($desktop) {
    width: 290px;
    padding-top: 54px;
  }
}

.contact__schedule {
  margin: 0;
  line-height: 22px;

  span {
    font-family: $fontBold;
  }
}

.contact__form-wrapper {
  @media ($tablet) {
    margin-top: 60px;
    width: 100%;
    padding-bottom: 60px;
  }

  @media ($desktop) {
    width: 470px;
    margin-top: 0;
  }
}

.contact__form-title {
  font-family: $fontMedium;
  font-size: 18px;
  line-height: 27px;
  margin: 0 0 20px;

  @media ($tablet) {
    width: 440px;
  }
}

.contact__form {
  @media ($tablet) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }
}

.contact__form-item {
  margin-bottom: 15px;

  @media ($tablet) {
    width: 48%;
    margin-bottom: 30px;

    &--textarea {
      width: 100%;
    }

    &--button {
      order: 5;
    }
  }

  @media ($desktop) {
    margin-bottom: 15px;
  }

  .input,
  .btn,
  .textarea {
    width: 100%;
  }
}

.contact__map {
  width: 100%;
  height: 470px;

  @media ($tablet) {
    height: 768px;
  }

  @media ($desktop) {
    height: 550px;
  }

  [class*="ymaps-2"][class*="-ground-pane"] {
    filter: grayscale(1);
  }
}