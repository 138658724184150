@import "../variables";

.breadcrumbs {
  margin: 0 0 10px;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}

.breadcrumbs__item {
  margin-right: 5px;

  &:not(:first-child)::before {
    content: '/ ';
    color: #9c9c9c;
  }

  &:last-child .breadcrumbs__link {
    pointer-events: none;
    color: #2c2f34;
  }
}

.breadcrumbs__link {
  line-height: 24px;
  color: #9c9c9c;
  text-decoration: none;
}