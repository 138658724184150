@import "../variables";

.input {
  border: none;
  padding: 0;
  background-color: transparent;
  box-sizing: border-box;

  &--default {
    height: 54px;
    border-radius: 6px;
    box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    padding: 0 22px;
    font-size: 16px;

    &::placeholder {
      opacity: .3;
      color: #000;
    }
  }

  &--check {
    &:checked + .label::before {
      border-color: #03bc4d;
      background: #03bc4d url('../img/svg/icon-check.svg') 50% 50% no-repeat;
    }
  }
}