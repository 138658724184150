@import "../variables";

.popup-callback {
  padding: 30px 15px 10px;

  @media ($tablet) {
    padding: 30px 30px 10px;
  }
}

.popup-callback__title {
  font-family: $fontBold;
  font-size: 20px;
  line-height: 32px;
  margin: 15px 0 0;

  @media ($tablet) {
    font-size: 22px;
    margin-bottom: 2px;
  }
}

.popup-callback__text {
  font-size: 14px;
  line-height: 22px;
  margin: 0;
}

.popup-callback__form {
  margin-top: 30px;
}

.popup-callback__item {
  margin-bottom: 20px;

  .btn,
  .input {
    width: 100%;
  }
}