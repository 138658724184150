@import "../variables";

.category {

}

.category__list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  @media ($tablet) {
    display: flex;
    flex-wrap: wrap;
  }
}

.category__item {
  position: relative;

  @media ($tablet) {
    width: 50%;
  }

  @media ($desktop) {
    width: 25%;
  }

  &--club {
    background-color: #303030;
  }

  &--hotel {
    background-color: #00af44;
  }

  &--restaurant {
    background-color: #fc8f32;
  }

  &--bathhouse {
    background-color: #bfd615;
  }
}

.category__img {
  width: 100%;
}

.category__link {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  color: #fff;
  text-decoration: none;
  min-height: 320px;
  justify-content: space-between;
  padding: 25px;
  box-sizing: border-box;
}

.category__title {
  margin: 0;
  font-family: $fontBold;
  font-size: 22px;
  line-height: 26px;
}

.category__more {
  margin: 0;
  text-align: right;
}