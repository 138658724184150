@import "../variables";

.options {
  padding: 30px 0;
}

.options__list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  @media ($tablet) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &--short {
    margin-top: 30px;

    .options__item {
      margin-bottom: 15px;

      @media ($tablet) {
        width: 47%;
        margin-bottom: 30px;
      }
    }
  }
}

.options__item {
  position: relative;

  @media ($tablet) {
    width: 33.33%;

    &--half {
      width: 50%;
    }
  }

  @media ($desktop) {
    &:hover {
      .options__text {
        display: block;
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
    opacity: .9;
  }

  img {
    width: 100%;
  }
}

.options__content {
  position: absolute;
  z-index: 1;
  left: 15px;
  right: 15px;
  bottom: 15px;
  color: #fff;

  @media ($desktop) {
    left: 30px;
    right: 30px;
    bottom: 30px;
  }
}

.options__title {
  font-family: $fontBold;
  font-size: 18px;
  line-height: 25px;
  margin: 0;

  @media ($tablet) {
    font-size: 24px;
    line-height: 33px;
  }
}

.options__text {
  display: none;

  @media ($desktop) {
    font-size: 14px;
    line-height: 22px;
    margin: 15px 0 0;
  }
}