@import "../variables";

.btn {
  margin: 0;
  padding: 0;
  display: inline-block;
  background-color: transparent;
  border: none;
  text-decoration: none;
  box-sizing: border-box;
  transition: background-color .1s, color .1s, box-shadow .1s;

  &--default {
    height: 54px;
    border-radius: 6px;
    background-color: #03bc4d;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $fontMedium;
    font-size: 16px;
    color: #fff;
    padding: 0 10px;

    &:hover {
      background-color: #3ade7c;
    }

    &:active {
      box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.2);
      background-color: #03bc4d;
    }
  }

  &--reverse {
    height: 54px;
    border-radius: 6px;
    border: solid 1px #03bc4d;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    color: #03bc4d;

    &:hover {
      background-color: #03bc4d;
      color: #fff;
    }

    &:active {
      box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.2);
      background-color: #03bc4d;
      color: #fff;
    }
  }
}