@font-face {
  font-family: 'TT Norms Regular';
  src: url('../fonts/TTNorms-Regular.woff') format('woff'),
       url('../fonts/TTNorms-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'TT Norms Medium';
  src: url('../fonts/TTNorms-Medium.woff') format('woff'),
       url('../fonts/TTNorms-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TT Norms Bold';
  src: url('../fonts/TTNorms-Bold.woff') format('woff'),
       url('../fonts/TTNorms-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}