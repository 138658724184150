@import "../variables";

.promo {
  margin-top: -60px;
  max-height: 620px;
  overflow: hidden;
  position: relative;

  @media ($tablet) {
    margin-top: -132px;
    max-height: 700px;
  }

  @media ($desktop) {
    margin-top: -83px;
  }
}

.promo__item {
  position: relative;
}

.promo__content {
  padding: 115px 0 103px;
  position: absolute;
  top: 0;
  bottom: 0;
  color: #fff;

  @media ($tablet) {
    padding: 195px 0 108px;

    .btn {
      width: 185px;
      margin-left: 120px;
    }
  }

  @media ($desktop) {
    left: 50%;
    transform: translateX(-50%);
    padding: 127px 0 68px;

    .btn {
      margin-left: 500px;
    }
  }
}

.promo__img {
  width: 100%;
  display: block;
}

.promo__title {
  margin: 0 0 30px;
  font-family: $fontBold;
  font-size: 32px;
  line-height: 44px;
  font-weight: 400;

  @media ($tablet) {
    width: 440px;
    font-size: 50px;
    line-height: 66px;
  }

  @media ($desktop) {
    width: 665px;
    font-size: 80px;
    line-height: 95px;
    margin: 0 auto 7px;
  }
}

.promo__text {
  margin: 0 0 30px;
  font-family: $fontMedium;
  font-size: 18px;
  line-height: 27px;

  @media ($tablet) {
    font-size: 20px;
    line-height: 29px;
    width: 325px;
    padding-left: 120px;
  }

  @media ($desktop) {
    width: 450px;
    font-size: 28px;
    line-height: 44px;
    padding-left: 500px;
  }
}

.promo__controls {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ($tablet) {
    bottom: 35px;
    justify-content: flex-end;
  }
}

.promo__pagination {
  position: static;
  width: auto;
  color: #fff;
  font-family: $fontBold;
  font-size: 24px;

  @media ($tablet) {
    margin-right: 30px;
  }

  .swiper-pagination-current {
    font-size: 40px;

    &::before {
      content: '0';
    }
  }

  .swiper-pagination-total {
    &::before {
      content: '0';
    }
  }
}

.promo__nav {
  display: flex;
  height: 53px;
}

.promo__next,
.promo__prev {
  position: static;
  width: 53px;
  height: 53px;
  background-color: #ffffff;
  background-image: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  transition: background-color .1s, color .1s, box-shadow .1s;

  &:hover {
    background-color: #03bc4d;
  }

  &:active {
    box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #03bc4d;
  }
}

.promo__prev  {
  border-radius: 8px 0 0 8px;

  &:hover .promo__prev-svg {
    fill: #fff;
  }
}

.promo__next {
  border-radius: 0 8px 8px 0;

  &:hover .promo__next-svg {
    fill: #fff;
  }
}

.promo__prev-svg,
.promo__next-svg {
  width: 26px;
  height: 30px;
  fill: #2c2f34;
}

.promo__scroll {
  display: none;

  @media ($desktop) {
    position: absolute;
    left: -100px;
    bottom: 70px;
    font-family: $fontBold;
    color: #fff;
    text-transform: uppercase;
    z-index: 10;
    display: block;
    transform: rotate(-90deg);

    &::before {
      content: '';
      width: 70px;
      height: 2px;
      display: inline-block;
      vertical-align: middle;
      background-color: #fff;
      margin-right: 20px;
      opacity: .3;
    }
  }
}