@import "../variables";

.attention {
  margin: 30px -15px;
  background-color: #fbfbfb;
  padding: 15px;
  font-family: $fontMedium;
  font-size: 18px;
  line-height: 26px;

  @media ($tablet) {
    width: 470px;
    margin: 30px 0;
    padding: 55px;
    font-size: 36px;
    line-height: 48px;
  }
}