@import "../variables";

.news {
  background-color: #f5f7f9;
  padding: 40px 0 60px;

  @media ($tablet) {
    padding: 300px 0 90px;
    margin-top: -280px;
  }

  @media ($desktop) {
    margin-top: -250px;
  }

  .title {
    text-align: center;

    @media ($tablet) {
      text-align: left;
      font-size: 32px;
      line-height: 45px;
      width: 450px;
    }

    @media ($desktop) {
      font-size: 40px;
      line-height: 55px;
      width: 565px;
    }
  }
}

.news__slider {
  @media ($tablet) {
    margin-right: -45px;
  }
}

.news__control {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  @media ($tablet) {
    justify-content: flex-end;
    margin-top: -65px;
  }
}

.news__next,
.news__prev {
  position: static;
  width: 53px;
  height: 53px;
  background-color: #ffffff;
  background-image: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  transition: background-color .1s, color .1s, box-shadow .1s;

  &:hover {
    background-color: #03bc4d;
  }

  &:active {
    box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #03bc4d;
  }
}

.news__prev  {
  border-radius: 8px 0 0 8px;

  &:hover .news__prev-svg {
    fill: #fff;
  }
}

.news__next {
  border-radius: 0 8px 8px 0;

  &:hover .news__next-svg {
    fill: #fff;
  }
}

.news__prev-svg,
.news__next-svg {
  width: 26px;
  height: 30px;
  fill: #2c2f34;
}

.news__item {
  background-color: #fff;
  padding: 30px 20px;
  box-sizing: border-box;

  @media ($tablet) {
    padding: 30px;
  }
}

.news__type {
  font-family: $fontBold;
  font-size: 14px;
  margin: 0 0 15px;
  text-transform: uppercase;
}

.news__title {
  font-family: $fontBold;
  font-size: 22px;
  margin: 0 0 17px;
  line-height: 31px;
}

.news__content {
  margin: 0 0 15px;
  line-height: 24px;
}

.news__info {
  display: flex;
  justify-content: space-between;
}

.news__date {
  margin: 0;
  font-size: 14px;
  line-height: 26px;
  color: #9c9c9c;
}

.news__link {
  line-height: 26px;
  text-decoration: none;
  color: #03bc4d;
}

.news__all {
  margin-top: 30px;
}

.news__all-link {
  font-family: $fontBold;
  font-size: 24px;
  line-height: 33px;
  color: #03bc4d;
  text-decoration: none;
}