@import "../variables";

.booking {
  border-top: 8px solid #03bc4d;
  border-bottom: 8px solid #03bc4d;
  padding: 20px 0 70px;

  @media ($tablet) {
    border: none;
  }

  .title {
    text-align: center;
    margin-bottom: 10px;

    @media ($tablet) {
      text-align: left;
      font-size: 36px;
    }
  }
}

.booking__content {
  @media ($tablet) {
    outline: 8px solid #03bc4d;
    padding: 36px 30px 10px;
    position: relative;
    background-color: #fff;

    &--paintball {
      &::after {
        content: url('../img/booking/booking-1.png');
        position: absolute;
        top: -58px;
        right: -10px;
      }
    }

    &--broom {
      &::after {
        content: url('../img/booking/booking-2.png');
        position: absolute;
        top: -58px;
        right: 20px;
      }
    }

    &--pillow {
      &::after {
        content: url('../img/booking/booking-3.png');
        position: absolute;
        top: -58px;
        right: 0;
      }
    }

    &--wine {
      &::after {
        content: url('../img/booking/booking-4.png');
        position: absolute;
        bottom: 0;
        right: 30px;
      }
    }
  }

  @media ($desktop) {
    width: 970px;
    margin: 0 auto;
  }
}

.booking__text {
  margin: 0;
  text-align: center;
  line-height: 24px;

  @media ($tablet) {
    text-align: left;
    font-size: 18px;
  }
}

.booking__form {
  margin-top: 50px;

  @media ($tablet) {
    width: 330px;
    margin-top: 30px;
    position: relative;
    z-index: 1;
  }

  @media ($desktop) {
    width: 690px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}

.booking__item {
  margin-bottom: 10px;

  @media ($tablet) {
    margin-bottom: 20px;
  }

  .input {
    width: 100%;
  }
  .btn {
    width: 100%;
    margin-bottom: 15px;

    @media ($desktop) {
      margin-bottom: 0;
    }
  }

  @media ($desktop) {
    width: 330px;
    margin-bottom: 30px;

    &--button {
      order: 4;
    }
  }
}