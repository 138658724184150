@import "../variables";

.title {
  font-family: $fontBold;
  font-weight: 400;
  margin: 0 0 30px;
  color: #2c2f34;

  &--default {
    font-size: 24px;
    line-height: 33px;

    @media ($tablet) {
      font-size: 28px;
      line-height: 38px;
    }

    @media ($desktop) {
      font-size: 36px;
      line-height: 49px;
    }
  }

  &--h1 {
    font-size: 32px;
    line-height: 43px;

    @media ($tablet) {
      font-size: 50px;
      line-height: 68px;
    }

    @media ($desktop) {
      font-size: 80px;
      line-height: 95px;
    }
  }

  &--h2 {
    font-size: 28px;
    line-height: 38px;

    @media ($tablet) {
      font-size: 40px;
      line-height: 44px;
    }

    @media ($desktop) {
      font-size: 60px;
      line-height: 70px;
    }
  }

  &--h3 {
    font-size: 24px;
    line-height: 33px;

    @media ($tablet) {
      font-size: 32px;
      line-height: 41px;
    }

    @media ($desktop) {
      font-size: 40px;
      line-height: 50px;
    }
  }

  &--h4 {
    font-size: 20px;
    line-height: 28px;

    @media ($tablet) {
      font-size: 28px;
      line-height: 36px;
    }

    @media ($desktop) {
      font-size: 32px;
      line-height: 45px;
    }
  }

  &--h5 {
    font-size: 18px;
    line-height: 25px;

    @media ($tablet) {
      font-size: 24px;
      line-height: 36px;
    }
  }

  &--h6 {
    font-size: 16px;
    line-height: 23px;

    @media ($tablet) {
      font-size: 18px;
      line-height: 30px;
    }
  }

  &--page {
    font-family: $fontMedium;
    font-size: 24px;
    line-height: 33px;
  }
}