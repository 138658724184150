@import "../variables";

.services {
  padding: 30px 0;

  @media ($tablet) {
    padding: 90px 0;
  }

  .title {
    margin-bottom: 15px;
  }
}

.services__header {
  margin-bottom: 15px;

  @media ($tablet) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;

    .btn {
      width: 205px;
    }
  }
}

.services__list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  @media ($tablet) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.services__item {
  margin-bottom: 30px;

  @media ($tablet) {
    width: 30%;

    &--half {
      width: 47%;
    }
  }
}

.services__title {
  font-family: $fontBold;
  font-size: 22px;
  margin: 0 0 15px;
}

.services__text {
  margin: 0 0 15px;
  line-height: 22px;
}

.services__link {
  color: #03bc4d;
}