@import "../variables";

.header {
  position: relative;
  z-index: 15;
}

.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo {
  @media ($tablet) {
    display: none;
  }
}

.header__button {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ($tablet) {
    display: none;
  }
}

.header__button-svg {
  width: 28px;
  height: 18px;
  fill: #fff;
}

.header__content {
  display: none;
  position: fixed;
  top: 0;
  right: 15px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  padding: 0 15px 46px;
  box-sizing: border-box;
  flex-direction: column;

  &--open {
    display: flex;
  }

  @media ($tablet) {
    display: flex;
    position: static;
    flex-direction: row;
    padding: 0;
    width: 100%;
    background-color: transparent;
    flex-wrap: wrap;
    align-items: center;
  }

  @media ($desktop) {
    justify-content: space-between;
  }
}

.header__close {
  @media ($tablet) {
    display: none;
  }
}

.header__close-svg {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 28px;
  right: 11px;
}

.header__contacts {
  margin-top: auto;
  display: flex;
  flex-direction: column;

  @media ($tablet) {
    margin-left: auto;
    margin-top: 0;
    padding-right: 30px;
  }

  @media ($desktop) {
    order: 3;
    margin-left: 0;
    padding-right: 0;
  }
}

.header__phone {
  font-family: $fontBold;
  font-size: 24px;
  line-height: 19px;
  color: #2c2f34;
  text-decoration: none;
  margin-bottom: 9px;

  @media ($tablet) {
    font-size: 20px;
    color: #fff;
    margin-bottom: 8px;
  }

  @media ($desktop) {
    font-size: 24px;
    margin-bottom: 9px;
  }
}

.header__callback {
  font-family: $fontBold;
  line-height: 19px;
  color: #2c2f34;
  transition: color .1s;

  &:hover {
    color: #03bc4d;
  }

  @media ($tablet) {
    color: #fff;
  }
}