@import "../variables";

.footer {
  background-color: #2c2f34;
  padding-bottom: 60px;
  color: #9c9c9c;

  @media ($tablet) {
    padding-bottom: 40px;
  }

  @media ($desktop) {
    padding-bottom: 30px;
  }
}

.footer__top {
  @media ($tablet) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 58px;
  }
}

.footer__ask {
  text-align: center;

  .btn {
    width: 210px;
    margin: 0 auto;
  }
}

.footer__ask-text {
  font-size: 14px;
  letter-spacing: -0.2px;
  margin: 20px 0 30px;

  @media ($tablet) {
    margin-top: 10px;
  }
}

.footer__nav-list {
  margin: 78px 0 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media ($tablet) {
    order: 3;
    margin-top: 50px;
    width: 100%;
  }

  @media ($desktop) {
    order: initial;
    width: auto;
    margin-top: 56px;
  }
}

.footer__nav-item {
  width: 130px;
  text-align: center;
  min-height: 55px;

  @media ($tablet) {
    width: auto;
    text-align: left;
    min-height: 0;
  }

  @media ($desktop) {
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.footer__nav-link {
  font-family: $fontMedium;
  line-height: 25px;
  letter-spacing: -0.36px;
  color: #fff;
  text-decoration: none;
  transition: color .1s;

  &:hover {
    color: #03bc4d;
  }

  @media ($tablet) {
    br {
      display: none;
    }
  }
}

.footer__contacts {
  width: 210px;
  margin: 0 auto;

  @media ($tablet) {
    width: auto;
    margin: 0;
    padding-top: 30px;
    padding-right: 100px;
  }

  @media ($desktop) {
    padding-right: 0;
  }
}

.footer__contacts-text {
  margin: 0 0 3px;
}

.footer__phone {
  font-family: $fontBold;
  font-size: 24px;
  letter-spacing: -0.5px;
  color: #fff;
  text-decoration: none;
}

.footer__social-item {
  margin: 0 8px;
}

.footer__social {
  margin: 28px 0 64px;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;

  @media ($tablet) {
    margin-bottom: 0;
    justify-content: flex-start;
  }
}

.footer__social-svg {
  width: 26px;
  height: 26px;
}

.footer__bottom {
  text-align: center;
  font-size: 12px;
  line-height: 18px;

  @media ($tablet) {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @media ($desktop) {
    align-items: flex-end;
  }
}

.footer__copy {
  @media ($tablet) {
    width: 50%;
  }

  @media ($desktop) {
    width: auto;
  }

  p {
    margin: 0 0 20px;

    @media ($tablet) {
      margin-bottom: 5px;
    }
  }
}

.footer__privacy {
  color: #9c9c9c;

  @media ($desktop) {
    width: 340px;
  }
}

.footer__dev {
  margin: 30px 0 0;

  @media ($tablet) {
    width: 100%;
    margin-top: 10px;
    text-align: right;
  }

  @media ($desktop) {
    width: auto;
    margin-top: 0;
  }
}

.footer__dev-link {
  color: #9c9c9c;
  text-decoration: none;
}