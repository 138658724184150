@import "../variables";

.faq {
  margin: 30px -15px;

  @media ($tablet) {
    margin: 30px 0;
  }
}

.faq__item {
  padding: 15px;
  border-bottom: 1px solid #d8d8d8;

  @media ($tablet) {
    padding: 18px 15px 21px;
  }

  &:first-child {
    border-top: 1px solid #d8d8d8;
  }
}

.faq__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--open + .faq__content {
    display: block;
  }

  &--open .faq__btn {
    transform: rotate(-45deg);
  }
}

.faq__title {
  width: 90%;
  font-family: $fontMedium;
  font-size: 18px;
}

.faq__btn {
  transition: all .2s;
}

.faq__svg-plus {
  width: 12px;
  height: 12px;
}

.faq__content {
  line-height: 23px;
  margin-top: 10px;
  display: none;
}