@import "../variables";

.label {
  display: inline-block;
  box-sizing: border-box;

  &--check {
    padding-left: 22px;
    position: relative;
    font-size: 14px;
    line-height: 21px;

    a {
      text-decoration: none;
      color: #00be47;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 3px;
      width: 14px;
      height: 14px;
      border-radius: 2px;
      box-sizing: border-box;
      border: 1px solid #2d2d2d;
    }
  }
}