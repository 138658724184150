@import "../variables";

.page {
  padding: 30px 0;
}

.page__content {
  p,
  .page__text {
    line-height: 24px;
    margin: 20px 0;
  }

  ul {
    margin: 30px 0;
    padding: 0;
    list-style-type: none;

    li {
      line-height: 22px;
      margin-bottom: 15px;

      &::before {
        content: '';
        width: 8px;
        height: 8px;
        background-color: #03bc4d;
        display: inline-block;
        vertical-align: middle;
        border-radius: 100%;
        margin-right: 15px;
      }
    }
  }

  ol {
    margin: 30px 0;
    padding: 0 0 0 12px;

    li {
      line-height: 26px;
      margin-bottom: 15px;
    }
  }
}

.page__text {
  &--col2 {
    @media ($tablet) {
      column-count: 2;
      column-gap: 30px;
    }
  }
}

.page__img {
  &--full {
    width: 100%;
  }

  &--left {
    @media ($tablet) {
      float: left;
      margin-right: 30px;
      margin-bottom: 20px;
    }
  }
}

.page__wrapper {
  @media ($desktop) {
    padding: 0 100px;
  }
}